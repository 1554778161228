import { Fragment } from "react";
import { Route, useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MarketingClientList } from "./MarketingClientList";
import { MarketingCampaignScreen } from "./MarketingCampaignScreen";
import { MarketingTemplateScreen } from "./MarketingTemplateScreen";
import { Box, Tabs } from "@mantine/core";

export const MarketingScreenRoutes = () => {
  return (
    <>
      <Route exct path="/marketing/:tab" element={<MarketingScreen />} />
      {MarketingTabs.map((route) => (
        <Fragment key={route.value}>
          {route.disabled !== true && (
            <Route
              exact
              path={`/marketing/${route.value.replace(/_/g, "-")}/:key`}
              element={route.route ? route.route : route.component}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};

export const MarketingTabs = [
  {
    value: "client_list",
    color: "green",
    disabled: false,
    component: <MarketingClientList />,
  },
  {
    value: "campaigns",
    color: "blue",
    disabled: false,
    component: <MarketingCampaignScreen />,
  },
  {
    value: "email_templates",
    color: "red",
    disabled: false,
    component: <MarketingTemplateScreen />,
  },
];

const MarketingScreen = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  return (
    <div style={{ padding: 8 }}>
      <Tabs
        keepMounted={false}
        color="teal"
        value={tab}
        onTabChange={(value) => navigate(`/marketing/${value}`)}
      >
        <Tabs.List>
          {MarketingTabs.map((tab) => (
            <Tabs.Tab
              value={tab.value.replace(/_/g, "-")}
              color={tab.color}
              disabled={tab.disabled}
            >
              <FormattedMessage id={tab.value} />
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {MarketingTabs.map((tab) => (
          <Tabs.Panel value={tab.value.replace(/_/g, "-")}>
            {tab.disabled !== true && tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

export default MarketingScreen;
