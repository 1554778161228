const initGoogleMapsApi = (isLoggedIn) => {
  const scriptId = "google-maps-api-script";
  const scriptExists = !!document.getElementById(scriptId);

  if (isLoggedIn && !scriptExists) {
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCuqPajGGzPY2o6mjSkktTutium8pBdgPg&libraries=places&callback=Function.prototype`;
    script.async = true;
    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) document.head.removeChild(existingScript);
    };
  }
};

export default initGoogleMapsApi;
