import { Badge } from "@mantine/core";
import { useIntl } from "react-intl";

export const EventCard = ({ event }) => {
  const intl = useIntl();

  return (
    <a
      style={{
        border: "0.5px lightgrey solid",
      }}
      href={"/event/" + event.event_id}
      className="block px-4 py-2 text-sm text-gray-700"
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            maxWidth: "80%",
            textDecoration:
              event.event_status == "cancel" ? "line-through" : "none",
          }}
        >
          {event.event_title}
        </span>
        <div style={{ textAlign: "right" }}>
          {event.event_status == "cancel" && (
            <>
              <Badge size="xs" color="red">
                {intl.formatMessage({ id: "event_cancel" })}
              </Badge>
              <br />
            </>
          )}
          <Badge
            size="xs"
            color={
              event.event_type == "meeting"
                ? "green"
                : event.event_type == "event"
                ? "red"
                : null
            }
          >
            {event.event_type}
          </Badge>
        </div>
      </span>
      <span
        style={{
          fontSize: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>{new Date(event.event_start_time).toLocaleString()}</span>
        {event.event_end_time && " - "}
        <span>
          {event.event_end_time
            ? `${new Date(event.event_end_time).toLocaleString()}`
            : ""}
        </span>
      </span>
    </a>
  );
};
