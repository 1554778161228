import React, { createContext, useContext, useEffect, useState } from "react";
import { getSettings } from "../functions/api";

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children, use }) => {
  const [general, setGeneral] = useState({});
  const [defaultSettings, setDefaultSettings] = useState({});
  const [settingEmails, setSettingEmails] = useState({});

  useEffect(() => {
    if (use) {
      getSettings().then((data) =>
        data.map(async (item) => {
          const stng = await JSON.parse(item.settings);
          switch (item.type) {
            case "general":
              setGeneral({ ...item, ...stng });
              break;
            case "default":
              setDefaultSettings({ ...item, ...stng });
              break;
            case "emails":
              setSettingEmails({ ...item, ...stng });
              break;
            default:
          }
        })
      );
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{ general, defaultSettings, settingEmails }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
