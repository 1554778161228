import { Button, Checkbox } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Textarea, Select, MultiSelect, Modal, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import {
  cancelEvent,
  createEvent,
  getReportFilters,
  updateEvent,
} from "../../functions/api";
import { FormattedMessage, useIntl } from "react-intl";
import LocationAutocomplete from "../../functions/LocationAutocomplete";
import { getUserId } from "../../functions/tokens";
import { EditableLabels } from "../Inputs/EditableLabels";
import UserMultiSelect from "../Inputs/UserMultiSelect";
import EventScreen from "../../Screens/Client/EventScreen";
import PinButton from "../Buttons/PinButton";

export default function EventOverlay({
  open,
  setOpen,
  data,
  newTabBtn = true,
}) {
  const intl = useIntl();
  const cancelButtonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [desc, setDesc] = useState("");
  const [user, setUser] = useState();
  const [filters, setFilters] = useState();
  const [address, setAddress] = useState();
  const [involvedUsers, setInvolvedUsers] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [responsible, setResponsible] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [link, setLink] = useState(null);
  const [privateEvent, setPrivate] = useState(null);
  const [madeBy, setMadeBy] = useState(null);
  const [clients, setClients] = useState(null);
  const [preData, setPreData] = useState(null);

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  useEffect(() => {
    setStartTime(
      data?.event_start_time ? new Date(data?.event_start_time) : null
    );
    setPrivate(data?.visability == 0);
    setAddress({
      formatted_address: data?.address,
      geometry: {
        location: {
          lng: data?.coords?.x ? data?.coords?.x : null,
          lat: data?.coords?.y ? data?.coords?.y : null,
        },
      },
    });
    setEndTime(data?.event_end_time ? new Date(data?.event_end_time) : null);
    setName(data?.event_title);
    setEventType(data?.event_type);
    setResponsible(Number(data?.main_responsible));
    setDesc(data?.event_description);
    setInvolvedUsers(data?.involved_users);
    setLink(data?.event_link);
    setEventId(data?.event_id);
    setMadeBy(data?.created_by);
    const arrayWithoutNulls = data?.client_ids?.filter((item) => item !== null);
    setClients(arrayWithoutNulls && arrayWithoutNulls);
    setPreData(data?.clients);
    setLink(data?.event_link);
  }, [open]);

  if (!open) {
    return null;
  }

  const submitForm = () => {
    const sendData = {
      ...data,
      new_deadline: selectedDate,
      new_name: name,
      new_desc: desc,
      new_comment: comment,
      action: "event",
      assigned_to: user ? user : null,
      address: address,
      involved_users: involvedUsers,
      event_start: startTime,
      event_end: endTime,
      event_type: eventType,
      main_responsible: responsible,
      original_involved: data?.involved_users,
      original_clients: data?.client_ids?.filter((item) => item !== null),
      event_id: eventId ? eventId : null,
      private: privateEvent,
      clients: clients,
      event_link: link,
    };
    if (eventId) {
      updateEvent(sendData).then((x) => {
        window.alert("Pasākums pielabots!");
        handleClose();
      });
    } else {
      createEvent(sendData).then((x) => {
        window.alert("Pasākums izveidots!");
        handleClose();
      });
    }
  };

  const cancelEventConfirmation = () => {
    if (window.confirm(intl.formatMessage({ id: "cancel_event_question" }))) {
      cancelEvent({ event_id: data?.event_id, action: "cancel" }).then((x) => {
        handleClose();
        window.location.href = "/";
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDate(null);
    setName(null);
    setComment(null);
    setDesc(null);
    setUser(null);
    setAddress(null);
    setStartTime(null);
    setEndTime(null);
    setName(null);
    setEventType(null);
    setResponsible(null);
    setDesc(null);
    setInvolvedUsers(null);
    setEventId(null);
    setMadeBy(null);
    setLink(null);
  };

  const editable =
    data?.event_status !== "cancel" &&
    data?.event_status !== "deleted" &&
    (Number(data?.created_by) === Number(getUserId()) ||
      data?.event_id == null);

  return (
    <Modal
      opened={open}
      onClose={handleClose}
      title={
        data?.event_id
          ? data?.created_by != getUserId()
            ? intl.formatMessage({
                id: "edit_event",
              })
            : name
          : intl.formatMessage({
              id: "new_event",
            })
      }
    >
      {editable ? (
        <>
          {data?.creator_name && (
            <div style={{ marginTop: -5, marginBottom: 10 }}>
              <small>
                {intl.formatMessage({ id: "created_by" })}
                {data?.creator_name}
              </small>
            </div>
          )}
          <div
            style={{
              marginBottom: 10,
            }}
          >
            {data?.event_id && (
              <PinButton itemType="event" itemId={data?.event_id} />
            )}
            {data?.event_id && newTabBtn && (
              <>
                <a
                  target="_blank"
                  style={{
                    color: "dodgerblue",
                    marginBottom: 10,
                    float: "right",
                  }}
                  href={`/event/${data.event_id}`}
                >
                  {intl.formatMessage({ id: "open_new_tab" })}
                </a>
                <br />
              </>
            )}
          </div>
          <>
            <EditableLabels
              edit={editable}
              data={name && name}
              label={intl.formatMessage({
                id: "new_event_name",
              })}
              onInputChange={(e) => setName(e)}
            />
            <Select
              searchable
              clearable
              multiple={false}
              data={[
                {
                  label: intl.formatMessage({
                    id: "meeting",
                  }),
                  value: "meeting",
                },
                {
                  label: intl.formatMessage({
                    id: "online_meeting",
                  }),
                  value: "online",
                },
                {
                  label: intl.formatMessage({
                    id: "event",
                  }),
                  value: "event",
                },
              ]}
              label={intl.formatMessage({
                id: "event_type",
              })}
              placeholder={intl.formatMessage({ id: "event_type" })}
              value={eventType}
              onChange={setEventType}
            />
            <Select
              searchable
              clearable
              multiple={false}
              data={filters ? filters?.users : []}
              label={intl.formatMessage({
                id: "main_responsible",
              })}
              placeholder={intl.formatMessage({ id: "search_user" })}
              value={responsible}
              onChange={setResponsible}
            />
            <MultiSelect
              searchable
              clearable
              multiple
              data={filters ? filters?.users : []}
              label={intl.formatMessage({
                id: "involved_users",
              })}
              placeholder={intl.formatMessage({ id: "search_users" })}
              value={involvedUsers}
              onChange={setInvolvedUsers}
            />
            <UserMultiSelect
              selectedValues={clients ? clients : []}
              setSelectedValues={setClients}
              preData={preData ? preData : []}
            />
            <DateTimePicker
              label={intl.formatMessage({
                id: "select_datetime_start",
              })}
              placeholder={intl.formatMessage({
                id: "select_datetime",
              })}
              minDate={null}
              value={startTime}
              onChange={(date) => setStartTime(date)}
              popoverProps={{ withinPortal: true }}
            />
            <DateTimePicker
              label={intl.formatMessage({
                id: "select_datetime_end",
              })}
              placeholder={intl.formatMessage({
                id: "select_datetime",
              })}
              minDate={startTime ? startTime : null}
              value={endTime}
              onChange={(date) => setEndTime(date)}
              popoverProps={{ withinPortal: true }}
            />

            <LocationAutocomplete
              defaultValue={address?.formatted_address}
              callBack={setAddress}
            />
            <TextInput
              value={link}
              onChange={(e) => setLink(e.target.value)}
              label={intl.formatMessage({
                id: "event_link",
              })}
            />
            <Textarea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              label={intl.formatMessage({ id: "event_description" })}
              style={{ marginTop: 20 }}
            />
            <div style={{ display: "flex", marginTop: 10 }}>
              <Checkbox
                checked={privateEvent}
                onChange={(event) => setPrivate(event.currentTarget.checked)}
                style={{ marginRight: 5 }}
              />
              <FormattedMessage id="private_event" />
            </div>
          </>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            {eventId && eventId && madeBy == getUserId() && (
              <Button
                onClick={() => cancelEventConfirmation()}
                style={{ backgroundColor: "red", color: "white" }}
              >
                <FormattedMessage id="cancel_event" />
              </Button>
            )}
            <div>
              {!eventId && (
                <Button
                  onClick={() => submitForm()}
                  style={{ backgroundColor: "dodgerblue", color: "white" }}
                >
                  {eventId ? "Labot" : "Izveidot"}
                </Button>
              )}

              {eventId &&
                (eventId && madeBy == getUserId() ? (
                  <Button
                    onClick={() => submitForm()}
                    style={{ backgroundColor: "dodgerblue", color: "white" }}
                  >
                    {eventId ? "Labot" : "Izveidot"}
                  </Button>
                ) : (
                  <Button disabled>Labot var tikai pasākuma veidotājs</Button>
                ))}

              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleClose}
                ref={cancelButtonRef}
              >
                <FormattedMessage id="cancel" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <a
              target="_blank"
              style={{
                color: "dodgerblue",
                marginBottom: 10,
                float: "right",
              }}
              href={`/event/${data.event_id}`}
            >
              {intl.formatMessage({ id: "open_new_tab" })}
            </a>
            <br />
          </div>
          <div>
            <EventScreen
              data={data}
              container={false}
              id={eventId}
              editButton={false}
            />
          </div>
        </>
      )}
    </Modal>
  );
}
