import { url } from "./api";
import { getToken } from "./tokens";

export const uploadImageToServer = async (file) => {
  const token = getToken();
  const formData = new FormData();
  formData.append("file", file.attachments[0]); // The file to upload
  formData.append("data", JSON.stringify({ name: Date.now() })); // Additional data, adjust as needed

  try {
    const response = await fetch(url + "files/upload-marketing", {
      method: "POST",
      headers: {
        Authorization: `${token}`,
      },
      body: formData,
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, url: data.location };
    } else {
      console.error("Server error:", data.message);
      return { success: false, error: data.message };
    }
  } catch (error) {
    console.error("Upload error:", error);
    return { success: false, error: error.toString() };
  }
};
