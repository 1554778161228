export function getToken() {
  return localStorage.getItem("token");
}

export function removeToken() {
  localStorage.removeItem("token");
}

export function setToken(token) {
  localStorage.setItem("token", token);
}

export function getUserId() {
  return localStorage.getItem("id");
}

export function setUserId(id) {
  localStorage.setItem("id", id);
}

export function removeUserId() {
  return localStorage.removeItem("id");
}

export function getGroup() {
  return localStorage.getItem("group");
}

export function setGroup(group) {
  return localStorage.setItem("group", group);
}

export function removeGroup() {
  return localStorage.removeItem("group");
}

export function getData() {
  return JSON.parse(localStorage.getItem("data"));
}

export function setData(data) {
  return localStorage.setItem("data", JSON.stringify(data));
}

export function removeData() {
  return localStorage.removeItem("data");
}

export function getCallToken() {
  return localStorage.getItem("ct");
}

export function setCallToken(data) {
  return localStorage.setItem("ct", data);
}

export function removeCallToken() {
  return localStorage.removeItem("ct");
}

export function getLanguage() {
  return localStorage.getItem("locale");
}
