import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
} from "@mantine/core";
import {
  CreditCard,
  Receipt,
  UserPlus,
  FileDollar,
  FriendsOff,
  File3d,
  User,
  MailFast,
} from "tabler-icons-react";
import AddInvoiceScreen from "../Screens/Client/AddInvoiceScreen";
import PartnerScreen from "../Screens/Client/PartnerScreen";
import hasAccess from "../functions/access";
import { getGroup } from "../functions/tokens";
import AddSourceScreen from "../Screens/User/SourceScreen";
import { useLocation, useNavigate } from "react-router-dom";
import CauseScreen from "../Screens/User/CauseScreen";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { getClient } from "../functions/api";
import ClientOverviewScreen from "../Screens/Client/ClientOverviewScreen";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    paddingBottom: 7,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: 60,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",
    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
    },
  },
}));

export function ActionBar({ action, title, hideClients }) {
  const [client, setClient] = useState(null);

  const group = getGroup();

  const location = useLocation();
  let routes = location.pathname.split("/");

  if (
    (routes[1] == "invoices" || routes[1] == "partner") &&
    routes[2] != "" &&
    routes[2] != "new"
  ) {
    if (client != null) {
      getClient(routes[2], false).then((res) => {
        setClient(res.clientInfo);
      });
    }
  }

  const data = [
    hasAccess(group, "invoices") && {
      key: 1,
      title: <FormattedMessage id="invoices" />,
      icon: CreditCard,
      color: "violet",
      route: "/invoices",
      nav: true,
    },
    hasAccess(group, "invoices", ["view", "insert"]).length >= 2 && {
      key: 2,
      title: <FormattedMessage id="new_invoice" />,
      icon: FileDollar,
      color: "blue",
      component: (
        <AddInvoiceScreen
          preclient={
            client && {
              ...client,
              ...{ name: client.c_name, surname: client.c_surname },
            }
          }
        />
      ),
      route: "/invoices/new",
    },
    !hideClients && {
      key: 3,
      title: <FormattedMessage id="clients" />,
      icon: User,
      color: "teal",
      xcomponent: <ClientOverviewScreen />,
      route: "/clients",
      nav: true,
    },
    {
      key: 4,
      title: <FormattedMessage id="new_client" />,
      icon: UserPlus,
      color: "green",
      component: <PartnerScreen modify={true} newEntry={true} />,
      route: "/partner/new",
    },
    group >= 0 &&
      group <= 9 && {
        key: 5,
        title: <FormattedMessage id="add_source" />,
        icon: Receipt,
        color: "orange",
        component: <AddSourceScreen modify={true} newEntry={true} />,
      },
    group >= 0 &&
      group <= 9 && {
        key: 6,
        title: <FormattedMessage id="closing_reasons" />,
        icon: FriendsOff,
        color: "pink",
        component: <CauseScreen />,
      },
    group >= 0 &&
      group <= 9 && {
        key: 7,
        title: <FormattedMessage id="marketing" />,
        icon: MailFast,
        color: "pink",
        route: "/marketing/campaigns",
        nav: true,
      },
    !{
      key: 8,
      title: <FormattedMessage id="files" />,
      icon: File3d,
      color: "red",
      route: "/files",
      nav: true,
      disabled: true,
    },
  ].filter(Boolean);

  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div style={{ overflow: "auto" }}>
        <div style={{ minWidth: 500 }}>
          <Card withBorder radius="md" className={classes.card}>
            <SimpleGrid cols={data.length}>
              {data &&
                data.map((item) => (
                  <UnstyledButton
                    disabled={item.disabled ? true : false}
                    key={item.key}
                    className={classes.item}
                    onClick={() => {
                      item.nav ? navigate(item.route) : action(item);
                    }}
                  >
                    <item.icon
                      color={
                        item.disabled ? "grey" : theme.colors[item.color][6]
                      }
                      size={24}
                    />
                    <Text
                      size="xs"
                      mt={2}
                      color={item.disabled ? "grey" : "black"}
                    >
                      {item.title}
                    </Text>
                  </UnstyledButton>
                ))}
            </SimpleGrid>
          </Card>
        </div>
      </div>
    </>
  );
}
