import { useState } from "react";
import Papa from "papaparse";

export const useCsvParser = () => {
  const [parsedData, setParsedData] = useState([]);

  const parseCsv = (file, callback, columnMappings = {}) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        const { data, errors } = result;

        if (errors.length > 0) {
          //console.error("CSV Parsing errors:", errors);
          //return new Error("123");
          if (callback) callback({ error: errors });
          return;
        }

        // Define regular expressions for guessing column names
        const regexMappings = {
          email: /e-?mail|ēpasts|pasts|emails/i,
          name: /(?:\b|_)(name|first[_ ]name|vards|vārds|nosaukums|pilnais vards)/i,
          surname: /surname|last[_ ]name|uzvārds|uzvards/i,
          phone: /phone|telephone|mobile phone|telefons|numurs|mobilais/i,
        };

        // Find column indices based on provided mappings or regex guesses
        const findColumnIndex = (rows, columnName, regex) =>
          columnMappings[columnName]
            ? rows.findIndex(
                (header) =>
                  header.toLowerCase() ===
                  columnMappings[columnName].toLowerCase()
              )
            : rows.findIndex((header) => regex.test(header));

        const headers = Object.keys(data[0]);
        const columnIndices = {
          email: findColumnIndex(headers, "email", regexMappings.email),
          name: findColumnIndex(headers, "name", regexMappings.name),
          surname: findColumnIndex(headers, "surname", regexMappings.surname),
          phone: findColumnIndex(headers, "phone", regexMappings.phone),
        };

        // Extract data using found column indices
        const extractedData = data.map((row) => {
          const rowData = {};
          Object.entries(columnIndices).forEach(([key, index]) => {
            rowData[key] = index !== -1 ? row[headers[index]] : undefined;
          });
          return rowData;
        });

        setParsedData(extractedData);
        if (callback) callback(extractedData);
      },
    });
  };

  return [parsedData, parseCsv];
};
