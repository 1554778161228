import { useEffect, useState } from "react";
import {
  getMarketingClientList,
  getMatchingMarketingClientList,
  getReportFilters,
  saveMarketingClientList,
} from "../../../functions/api";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  FileInput,
  Grid,
  Loader,
  Modal,
  MultiSelect,
  Tabs,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePickerInput } from "@mantine/dates";
import { stateObject } from "../AdminReports";
import ClientTable from "../../../Components/ClientTable";
import { useCsvParser } from "../../../Hooks/useCsvParser";
import { NewButton } from "../../../Components/Buttons/NewButton";
import { emailRegex } from "../../../functions/regex";
import { notifications } from "@mantine/notifications";
import { BackButton } from "../../../Components/Buttons/BackButton";

export const MarketingClientList = () => {
  const { key } = useParams();
  const intl = useIntl();
  const [values, setValues] = useState(stateObject);
  const [found, setFound] = useState([]);
  const [foundCount, setFoundCount] = useState(null);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState(null);
  const [change, setChange] = useState(true);
  const [modal, setModal] = useState(false);
  const [tmpCsv, setTmpCsv] = useState([]);
  const [error, setError] = useState(null);
  const [type, setType] = useState("list");
  const [dsbl, setDsbl] = useState(false);
  const [list, setList] = useState([]);

  const [parsedData, parseCsv] = useCsvParser();

  const mapUniqueEmails = (data) => {
    const clientIds = data
      ?.filter((client) => {
        const email = client.email;
        return email && !/[,/]/.test(email) && emailRegex.test(email);
      })
      .map((client) => client.email);
    return [...new Set(clientIds)];
  };

  const mapUsersToSelected = (data = found) => {
    const clientIds = mapUniqueEmails(data);
    setSelected(clientIds);
  };

  useEffect(() => {
    if (key == "new" || !isNaN(key)) {
      getReportFilters().then((x) => setFilters(x));
    }
  }, []);

  useEffect(() => {
    if (!isNaN(key)) {
      getMarketingClientList({ id: key }).then((x) => {
        setValues((prevState) => ({
          ...prevState,
          email_list_name: x[0].email_list_name,
          email_list_description: x[0].email_list_description,
          id: x[0].email_list_id,
        }));
        mapUsersToSelected(x[0].list_entries);
        setChange(false);
        setType("selected");
      });
    } else if (!key) {
      getMarketingClientList({ id: null }).then((x) => setList(x));
    }
  }, []);

  if (key == "new" || !isNaN(key)) {
    const handleChange = (name) => (value) => {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (file) => {
      setTmpCsv([]);
      setError(false);
      if (file) {
        parseCsv(file, (data) => {
          if (data.error) {
            setError(true);
          } else {
            setTmpCsv(data);
          }
        });
      }
    };

    const addFromModalToEList = () => {
      const newList = found ? [...tmpCsv, ...found] : tmpCsv;
      setFound(newList);
      setFoundCount(newList.length);
      handleModalClose();
    };

    const handleModalClose = () => {
      setModal(false);
      setError(false);
      setTmpCsv([]);
    };

    const submitFilters = () => {
      getMatchingMarketingClientList(values).then((x) => {
        setFound(x);
        setFoundCount(x?.length);
        if (found == null || change) {
          mapUsersToSelected(x);
        }
      });
    };

    const handleCheckboxChange = (email, isChecked) => {
      if (isChecked) {
        setSelected((prevSelected) => [...prevSelected, email]);
      } else {
        setSelected((prevSelected) =>
          prevSelected?.filter((id) => id !== email)
        );
      }
    };

    const submitClientList = () => {
      if (
        values?.email_list_name?.trim() != "" &&
        values?.email_list_name != null
      ) {
        setDsbl(true);
        if (selected.length > 0) {
          const sendable = {
            info: values,
            list: selected,
            id: isNaN(key) ? null : key,
          };
          saveMarketingClientList(sendable).then((x) => {
            notifications.show({
              title: intl.formatMessage({ id: "saved" }),
              message: intl.formatMessage({ id: "saved_message" }),
              autoClose: 1000,
              onClose: () =>
                isNaN(key)
                  ? (window.location.href = `/marketing/client-list/${x[0].email_list_id}`)
                  : setDsbl(false),
            });
          });
        } else {
          window.alert(intl.formatMessage({ id: "list_cant_be_empty" }));
          setDsbl(false);
        }
      } else {
        window.alert(intl.formatMessage({ id: "name_cant_be_empty" }));
        setDsbl(false);
      }
    };
    const selectedColumns = [
      {
        key: "checkbox",
        title: null,
        searchable: false,
        render: (client) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={selected?.includes(client)}
              onChange={(event) =>
                handleCheckboxChange(client, event.currentTarget.checked)
              }
            />
          </div>
        ),
      },
      {
        key: "email",
        title: "email",
        searchable: true,
        render: (client) => client,
      },
    ];

    const columns = [
      {
        key: "checkbox",
        title: null,
        searchable: false,
        render: (client) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={selected?.includes(client.email)}
              onChange={(event) =>
                handleCheckboxChange(client.email, event.currentTarget.checked)
              }
            />
          </div>
        ),
      },
      {
        key: "name",
        title: "name",
        searchable: true,
        render: (client) => client.name,
      },
      {
        key: "surname",
        title: "surname",
        searchable: true,
        render: (client) => client.surname,
      },
      {
        key: "email",
        title: "email",
        searchable: true,
        render: (client) => client.email,
      },
    ];

    return (
      <>
        {!filters ? (
          <Loader />
        ) : (
          <>
            <BackButton />
            <Card withBorder mt={10}>
              <Title order={3}>
                {intl.formatMessage({ id: "email_list_data" })}
              </Title>
              <Grid>
                <Grid.Col xs={6}>
                  <TextInput
                    value={values.email_list_name}
                    label={intl.formatMessage({ id: "email_list_name" })}
                    onChange={(e) =>
                      handleChange("email_list_name")(e.target.value)
                    }
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <Textarea
                    value={values.email_list_description}
                    label={intl.formatMessage({ id: "email_list_description" })}
                    onChange={(e) =>
                      handleChange("email_list_description")(e.target.value)
                    }
                  />
                </Grid.Col>
              </Grid>
            </Card>
            <Card withBorder mt={10}>
              <Modal
                opened={modal}
                onClose={handleModalClose}
                title={intl.formatMessage({ id: "uplaod_data_from_file" })}
              >
                <FileInput
                  onChange={(files) => handleFileChange(files[0])}
                  multiple
                  label="Upload files"
                  placeholder="Upload files"
                  accept=".csv,.txt"
                />
                {error && (
                  <span style={{ color: "red" }}>
                    <FormattedMessage id="csv_read_error" />
                  </span>
                )}
                <div style={{ marginTop: 10 }}>
                  {tmpCsv.length > 0 && (
                    <>
                      <FormattedMessage id="results_found" />: {tmpCsv.length}
                      <br />
                      <FormattedMessage id="unique_emails" />:
                      {mapUniqueEmails(tmpCsv).length}
                    </>
                  )}
                </div>
                <div style={{ marginTop: 20 }}>
                  {tmpCsv.length > 0 && (
                    <Button
                      style={{ backgroundColor: "green", color: "white" }}
                      onClick={addFromModalToEList}
                    >
                      <FormattedMessage id="add_to_list" />
                    </Button>
                  )}
                </div>
              </Modal>
              <Title order={4}>
                {intl.formatMessage({ id: "include_in_campaign" })}
              </Title>
              <Grid>
                <Grid.Col xs={6}>
                  <MultiSelect
                    searchable
                    data={[
                      {
                        value: "closed",
                        label: intl.formatMessage({ id: "closed" }),
                      },
                      {
                        value: "active",
                        label: intl.formatMessage({ id: "active" }),
                      },
                    ]}
                    label={intl.formatMessage({ id: "client_status" })}
                    placeholder={intl.formatMessage({ id: "select_status" })}
                    value={values.clientStatus}
                    onChange={handleChange("clientStatus")}
                  />
                </Grid.Col>

                <Grid.Col xs={6}>
                  <MultiSelect
                    searchable
                    data={[
                      {
                        value: "all",
                        label: intl.formatMessage({ id: "all" }),
                      },
                      {
                        value: "physcial",
                        label: intl.formatMessage({ id: "physcial" }),
                      },
                      {
                        value: "legal",
                        label: intl.formatMessage({ id: "legal" }),
                      },
                      {
                        value: "unmarked",
                        label: intl.formatMessage({ id: "unmarked" }),
                      },
                    ]}
                    label={intl.formatMessage({ id: "client_legal" })}
                    placeholder={intl.formatMessage({ id: "select_status" })}
                    value={values.legal}
                    onChange={handleChange("legal")}
                  />
                </Grid.Col>

                <Grid.Col xs={6}>
                  <MultiSelect
                    searchable
                    data={filters ? filters?.users : []}
                    label={intl.formatMessage({ id: "client_creator" })}
                    placeholder={intl.formatMessage({ id: "select_status" })}
                    value={values.whoCreated}
                    onChange={handleChange("whoCreated")}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <MultiSelect
                    searchable
                    data={filters?.sources}
                    label={intl.formatMessage({ id: "client_source" })}
                    placeholder={intl.formatMessage({ id: "select_source" })}
                    value={values.source}
                    onChange={handleChange("source")}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <MultiSelect
                    searchable
                    data={filters?.closes}
                    label={intl.formatMessage({ id: "closing_reason" })}
                    placeholder={intl.formatMessage({ id: "select_status" })}
                    value={values.closedReason1}
                    onChange={handleChange("closedReason1")}
                  />
                </Grid.Col>
                <Grid.Col xs={4}>
                  <DatePickerInput
                    allowSingleDateInRange
                    clearable
                    type="range"
                    label={intl.formatMessage({ id: "creation_range" })}
                    placeholder={intl.formatMessage({ id: "pick_date_range" })}
                    value={values.creationRange}
                    onChange={handleChange("creationRange")}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col xs={4}>
                  <DatePickerInput
                    allowSingleDateInRange
                    clearable
                    type="range"
                    label={intl.formatMessage({ id: "closure_range" })}
                    placeholder={intl.formatMessage({ id: "pick_date_range" })}
                    value={values.closeRange}
                    onChange={handleChange("closeRange")}
                    mx="auto"
                  />
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Grid>
                    <Grid.Col
                      xs={6}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Button
                        style={{ backgroundColor: "dodgerblue" }}
                        onClick={() => submitFilters()}
                      >
                        <FormattedMessage id="search" />
                      </Button>
                      <span style={{ marginLeft: 20 }}>
                        {selected?.length > 0 && (
                          <>
                            <Checkbox
                              checked={change}
                              size="md"
                              onChange={(event) =>
                                setChange(event.currentTarget.checked)
                              }
                              label={<FormattedMessage id="override_saved" />}
                            />
                          </>
                        )}
                      </span>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                      {foundCount > 0 && (
                        <>
                          <FormattedMessage id="results_found" />: {foundCount}
                          {selected?.length > 0 && (
                            <>
                              <br /> <FormattedMessage id="results_selected" />:{" "}
                              {selected?.length} (
                              <FormattedMessage id="unique_emails" />)
                            </>
                          )}
                        </>
                      )}
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Card>
          </>
        )}

        <div style={{ marginTop: 20, marginBottom: 300 }}>
          <Card>
            <Grid>
              <Grid.Col xs={3}>
                <Button
                  onClick={() => setSelected([])}
                  style={{
                    width: "100%",
                    backgroundColor: selected?.length <= 0 ? "grey" : "red",
                  }}
                  disabled={selected?.length <= 0}
                >
                  <FormattedMessage id="remove_all" />{" "}
                  {selected?.length > 0 && `(${selected?.length})`}
                </Button>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Button
                  onClick={() => mapUsersToSelected()}
                  style={{ width: "100%", backgroundColor: "green" }}
                >
                  <FormattedMessage id="add_all" />
                </Button>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Button
                  onClick={() => setModal(true)}
                  style={{ width: "100%", backgroundColor: "orange" }}
                >
                  <FormattedMessage id="uplaod_data_from_file" />
                </Button>
              </Grid.Col>
              <Grid.Col xs={3}>
                <Button
                  disabled={dsbl}
                  onClick={() => submitClientList(selected)}
                  style={{ width: "100%", backgroundColor: "dodgerblue" }}
                >
                  <FormattedMessage id="save" />
                </Button>
              </Grid.Col>
            </Grid>
          </Card>
          <Card style={{ marginTop: 20 }}>
            <Tabs
              defaultValue="list"
              value={type}
              onTabChange={setType}
              variant="pills"
              color="orange"
            >
              <Tabs.List grow>
                <Tabs.Tab value="list">
                  <FormattedMessage id="list" />{" "}
                  <Badge color="orange" style={{ marginLeft: 10 }}>
                    {found?.length}
                  </Badge>
                </Tabs.Tab>
                <Tabs.Tab value="selected" color="blue">
                  <FormattedMessage id="selected_emails" />
                  <Badge style={{ marginLeft: 10 }}>{selected?.length}</Badge>
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>
            {found?.length > 0 && type == "list" && (
              <ClientTable clients={found} columns={columns} />
            )}
            {selected.length > 0 && type != "list" && (
              <ClientTable clients={selected} columns={selectedColumns} />
            )}
          </Card>
        </div>
      </>
    );
  }

  return (
    <Card withBorder mt={10}>
      <NewButton href="client-list/new" />
      <div style={{ marginTop: 15 }}>
        {list &&
          !list.error &&
          list?.map((entry) => (
            <a href={`/marketing/client-list/${entry.email_list_id}`}>
              <Card
                withBorder
                mt={4}
                mb={4}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <Title order={4}>
                    {entry.email_list_name} ({entry.list_entries.length})
                  </Title>
                  <div>
                    <small>{entry.email_list_description}</small>
                  </div>
                </div>
              </Card>
            </a>
          ))}
      </div>
    </Card>
  );
};
