import { useEffect, useState, useRef } from "react";
import {
  marketingGetTemplate,
  marketingCreateTemplate,
} from "../../../functions/api";
import {
  Button,
  Card,
  Grid,
  TextInput,
  Textarea,
  Title,
  LoadingOverlay,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { NewButton } from "../../../Components/Buttons/NewButton";
import { useIntl } from "react-intl";
import sample from "./sample.json";
import EmailEditor from "react-email-editor";
import { notifications } from "@mantine/notifications";
import { BackButton } from "../../../Components/Buttons/BackButton";
import { uploadImageToServer } from "../../../functions/uploadImage";

export const MarketingTemplateScreen = () => {
  const { key } = useParams();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const [temp, setTemp] = useState(null);
  const emailEditorRef = useRef(null);

  useEffect(() => {
    if (key && key != "new") {
      marketingGetTemplate({ id: key }).then(async (x) => {
        setTitle(x[0].title);
        setDescription(x.description);
        const fillData = { ...x[0], json: await JSON.parse(x[0].json) };
        setTemp(fillData);
        setLoading(false);
      });
    } else if (!key) {
      setLoading(false);
      marketingGetTemplate({ id: null }).then((x) => setTemp(x));
    } else {
      setLoading(false);
    }
  }, []);

  if (key) {
    const onDesignLoad = (data) => {};

    const onLoad = async (unlayer) => {
      if (!loading) {
        unlayer.loadDesign(temp?.json);
        unlayer.registerCallback("image", async (file, done) => {
          const { success, url, error } = await uploadImageToServer(file);
          if (success) {
            done({ url });
          } else {
            alert(`Failed to upload image: ${error}`);
          }
        });
      }
    };

    const onReady = (unlayer) => {};

    const exportHtml = () => {
      const unlayer = emailEditorRef.current?.editor;

      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        const sending = {
          title: title,
          description: description,
          json: design,
          html: html,
          id: key && key != "new" ? key : null,
        };
        marketingCreateTemplate(sending).then((x) => {
          if (key == "new") {
            window.location.href = `/marketing/email-templates/${x[0].template_id}`;
          } else {
            notifications.show({
              title: intl.formatMessage({ id: "saved" }),
              message: intl.formatMessage({ id: "saved_message" }),
              autoClose: 1000,
            });
          }
        });
      });
    };
    if (loading) {
      return <LoadingOverlay />;
    }
    return (
      <div>
        <BackButton />
        <Card withBorder style={{ marginTop: 5 }}>
          <Title order={3}>
            {intl.formatMessage({ id: "email_templates" })}
          </Title>
          <Grid>
            <Grid.Col xs={6}>
              <TextInput
                label={intl.formatMessage({ id: "template_title" })}
                name="title"
                value={title}
                onChange={(x) => setTitle(x.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Textarea
                label={intl.formatMessage({ id: "template_description" })}
                autosize
                minRows={2}
                name="title"
                value={description}
                onChange={(x) => setDescription(x.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Button
                onClick={exportHtml}
                style={{ backgroundColor: "dodgerblue", float: "right" }}
              >
                {intl.formatMessage({ id: "save_template" })}
              </Button>
            </Grid.Col>
          </Grid>
        </Card>
        <div
          style={{
            position: "relative",
            margin: 0,
            display: "flex",
            height: "65vh",
            marginTop: 5,
          }}
        >
          <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
        </div>
      </div>
    );
  }

  return (
    <Card withBorder mt={10}>
      <NewButton href="email-templates/new" />
      <div style={{ marginTop: 10 }}>
        {temp &&
          !temp.error &&
          temp?.map((x) => (
            <a href={`/marketing/email-templates/${x.template_id}`}>
              <Card withBorder>
                <Title order={3}>{x?.title ? x?.title : "Untitled"}</Title>
                {x?.description}
              </Card>
            </a>
          ))}
      </div>
    </Card>
  );
};
