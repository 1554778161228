import React, { useEffect, useState } from "react";
import { Table, ActionIcon, Input } from "@mantine/core";
import { Eye, Search } from "tabler-icons-react";
import { FormattedMessage, useIntl } from "react-intl";
import { PaginationItem } from "./PaginationItem";

const ClientTable = ({
  clients,
  columns,
  itemsPerPage = 40,
  actionsRow = false,
}) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredClients = clients.filter((client) =>
    columns.some((column) =>
      column.searchable
        ? column
            ?.render(client)
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase())
        : false
    )
  );

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const startIndex =
    currentPage > 0 ? currentPage - 1 : currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedClients = filteredClients.slice(startIndex, endIndex);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage]);

  let i = 0;
  return (
    <div>
      <Input
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
        icon={<Search />}
        placeholder={intl.formatMessage({ id: "search" })}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ marginBottom: "20px", justifyContent: "center" }}
        />
      )}
      <div style={{ overflowX: "auto" }}>
        <Table
          horizontalSpacing="sm"
          verticalSpacing="sm"
          striped
          highlightOnHover
          withBorder
          withColumnBorders
        >
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>
                  {column.title && <FormattedMessage id={column.title} />}
                </th>
              ))}
              {actionsRow && <th></th>}
            </tr>
          </thead>
          <tbody>
            {displayedClients.map((client) => (
              <tr key={client.clientid ? client.clientid : ++i}>
                {columns.map((column) => (
                  <td key={column.key}>{column.render(client)}</td>
                ))}
                {actionsRow && (
                  <td style={{ display: "flex", justifyContent: "center" }}>
                    {/* Example action icon */}
                    <a target="_blank" href={`/partner/${client.clientid}`}>
                      <ActionIcon color="green" size="lg" variant="light">
                        <Eye />
                      </ActionIcon>
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {totalPages > 1 && (
        <PaginationItem
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          style={{ marginTop: "20px", justifyContent: "center" }}
        />
      )}
    </div>
  );
};

export default ClientTable;
