import { Input } from "@mantine/core";
import { usePlacesWidget } from "react-google-autocomplete";
import { FormattedMessage, useIntl } from "react-intl";

const LocationAutocomplete = ({ callBack, defaultValue }) => {
  const intl = useIntl();
  const { ref, autocompleteRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      callBack(place);
    },
    options: {
      types: ["address"],
    },
  });

  return (
    <>
      <div style={{ marginTop: 15, zIndex: 1600 }}>
        <FormattedMessage id="add_location" />
        <div>
          <Input
            defaultValue={defaultValue}
            options={{
              types: ["address"],
            }}
            placeholder={intl.formatMessage({ id: "type_address" })}
            ref={ref}
          />
        </div>
      </div>
    </>
  );
};

export function extractLngLatFromPoint(pointStr) {
  const match = pointStr?.match(/POINT\(([^ ]+) ([^ ]+)\)/);
  return {
    lng: parseFloat(match[1]),
    lat: parseFloat(match[2]),
  };
}

export default LocationAutocomplete;
