import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Group,
  Select,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { NewButton } from "../../../Components/Buttons/NewButton";
import { useIntl } from "react-intl";
import {
  getMarketingClientList,
  getMarketingCampaign,
  marketingGetTemplate,
  saveMarketingCampaign,
  startMarketingCampaign,
  stopMarketingCampaign,
  relaunchMarketingCampaign,
  getSettings,
} from "../../../functions/api";
import { DatePickerInput } from "@mantine/dates";
import { notifications } from "@mantine/notifications";
import { BackButton } from "../../../Components/Buttons/BackButton";
import {
  At,
  Check,
  CircleCheck,
  MailFast,
  PlayerPause,
  PlayerPlay,
  Template,
} from "tabler-icons-react";

export const MarketingCampaignScreen = () => {
  const { key } = useParams();
  const intl = useIntl();

  const [values, setValues] = useState([]);
  const [lists, setLists] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [status, setStatus] = useState("unsaved");
  const [list, setList] = useState([]);
  const [emails, setEmails] = useState([]);

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (key == "new" || !isNaN(key)) {
      getMarketingClientList({ id: null }).then(
        (x) =>
          !x.error &&
          setLists(
            x?.map((item) => ({
              value: item.email_list_id,
              label: `${item.email_list_name} (${item.list_entries.length})`,
              description: item.email_list_description,
            }))
          )
      );
      marketingGetTemplate({ id: null }).then(
        (x) =>
          !x.error &&
          setTemplates(
            x?.map((item) => ({
              value: item.template_id,
              label: item.title,
              description: item.description,
            }))
          )
      );
      getSettings({ type: "emails" }).then((fetchedEmails) => {
        if (fetchedEmails[0]) {
          const data = JSON.parse(fetchedEmails[0].settings);
          setEmails(data);
        }
      });
    } else if (!key) {
      getMarketingCampaign({ id: null }).then((x) => setList(x));
    }
  }, []);

  useEffect(() => {
    if (!isNaN(key)) {
      getMarketingCampaign({ id: key }).then((x) => {
        setValues({
          ...x[0],
          start_on: x[0]?.start_on ? new Date(x[0]?.start_on) : null,
          id: x[0].campaign_id,
        });
        setStatus(x[0]?.status);
      });
    }
  }, []);

  const saveData = () => {
    if (values.campaign_name) {
      saveMarketingCampaign(values).then((x) =>
        key == "new"
          ? (window.location.href = `/marketing/campaigns/${x[0].campaign_id}`)
          : notifications.show({
              title: intl.formatMessage({ id: "saved" }),
              message: intl.formatMessage({ id: "saved_message" }),
              autoClose: 2000,
            })
      );
    } else {
      window.alert(intl.formatMessage({ id: "campaign_more_info" }));
    }
  };

  const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  if (key == "new" || !isNaN(key)) {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const launchCmpng = () => {
      if (!isNaN(key) && status != "unsaved" && status != "start") {
        if (
          values.subject &&
          values.send_from &&
          values.email_list &&
          values.email_template
        ) {
          startMarketingCampaign({ id: key }).then((x) => setStatus(x.msg));
        } else {
          window.alert(intl.formatMessage({ id: "information_missing" }));
        }
      }
    };

    const stopCampaign = () => {
      stopMarketingCampaign({ id: key }).then((x) => setStatus(x.msg));
    };

    const relaunchCampaing = () => {
      relaunchMarketingCampaign({ id: key }).then((x) => setStatus(x.msg));
    };

    return (
      <>
        <BackButton />
        <Card withBorder mt={10} style={{ overflow: "visible" }}>
          <Title order={3}>{intl.formatMessage({ id: "campaign" })}</Title>
          <Grid>
            <Grid.Col xs={6}>
              <TextInput
                label={intl.formatMessage({ id: "campaign_name" })}
                value={values?.campaign_name}
                onChange={(e) => handleChange("campaign_name")(e.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Textarea
                label={intl.formatMessage({ id: "campaign_description" })}
                value={values?.campaign_description}
                onChange={(e) =>
                  handleChange("campaign_description")(e.target.value)
                }
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <TextInput
                description={<span style={{ color: "#ffffffff" }}>_</span>}
                label={intl.formatMessage({ id: "subject" })}
                value={values?.subject}
                onChange={(e) => handleChange("subject")(e.target.value)}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Select
                required
                clearable
                label={intl.formatMessage({ id: "send_from" })}
                value={values?.send_from}
                description={intl.formatMessage({
                  id: "emails_should_be_added_settings",
                })}
                onChange={handleChange("send_from")}
                data={emails?.map((x) => {
                  const data = { value: x.user, label: x.user };
                  return data;
                })}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <DatePickerInput
                minDate={tomorrow}
                label={intl.formatMessage({ id: "start_on" })}
                value={values?.start_on != null ? values?.start_on : null}
                onChange={(e) => handleChange("start_on")(e)}
              />
            </Grid.Col>
            <Grid.Col xs={12}>
              <Grid>
                <Grid.Col xs={6}>
                  <Select
                    required
                    clearable
                    description={
                      values?.email_list ? (
                        <a
                          style={{
                            color: "dodgerblue",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                          href={`/marketing/client-list/${values?.email_list}`}
                        >
                          {intl.formatMessage({ id: "view_list" })}
                        </a>
                      ) : (
                        <span style={{ color: "#ffffffff" }}>_</span>
                      )
                    }
                    itemComponent={SelectItem}
                    label={intl.formatMessage({ id: "email_list" })}
                    value={values?.email_list}
                    onChange={handleChange("email_list")}
                    data={lists}
                  />
                </Grid.Col>
                <Grid.Col xs={6}>
                  <Select
                    required
                    clearable
                    description={
                      values?.email_template ? (
                        <a
                          style={{
                            color: "dodgerblue",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                          href={`/marketing/email-templates/${values?.email_template}`}
                        >
                          {intl.formatMessage({ id: "view_template" })}
                        </a>
                      ) : (
                        <span style={{ color: "#ffffffff" }}>_</span>
                      )
                    }
                    itemComponent={SelectItem}
                    label={intl.formatMessage({ id: "email_template" })}
                    value={values?.email_template}
                    onChange={handleChange("email_template")}
                    data={templates}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col xs={12}>
              <Grid>
                <Grid.Col xs={3}>
                  {(status == "draft" || status == "unsaved") && (
                    <Button
                      style={{ width: "100%", backgroundColor: "dodgerblue" }}
                      onClick={() => saveData(values)}
                    >
                      {intl.formatMessage({ id: "save" })}
                    </Button>
                  )}
                </Grid.Col>
                <Grid.Col xs={3}>
                  {status == "draft" && (
                    <Button
                      style={{ width: "100%", backgroundColor: "green" }}
                      onClick={launchCmpng}
                    >
                      {intl.formatMessage({ id: "launch_campaign" })}
                    </Button>
                  )}
                </Grid.Col>
                <Grid.Col xs={3}>
                  {(status == "start" || status == "re-start") && (
                    <Button
                      style={{ width: "100%", backgroundColor: "red" }}
                      onClick={stopCampaign}
                    >
                      {intl.formatMessage({ id: "stop_campaign" })}
                    </Button>
                  )}
                </Grid.Col>
                {status == "done" && (
                  <Grid.Col xs={3}>
                    <div style={{ display: "flex" }}>
                      <Title order={3}>
                        {intl.formatMessage({ id: "campaign_done" })}
                      </Title>
                      <Check color="green" />
                    </div>
                  </Grid.Col>
                )}
                <Grid.Col xs={3}>
                  {status == "stop" && (
                    <Button
                      style={{ width: "100%", backgroundColor: "orange" }}
                      onClick={relaunchCampaing}
                    >
                      {intl.formatMessage({ id: "re_launch_campaign" })}
                    </Button>
                  )}
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Card>
      </>
    );
  }

  return (
    <Card withBorder mt={10}>
      <NewButton href="/marketing/campaigns/new" />
      <div style={{ marginTop: 15 }}>
        {list &&
          !list.error &&
          list?.map((entry) => (
            <a href={`/marketing/campaigns/${entry.campaign_id}`}>
              <Card
                withBorder
                mt={4}
                mb={4}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor:
                    entry.status == "start"
                      ? "#fc880330"
                      : entry.status == "stop"
                      ? "#fc030330"
                      : entry.status == "done"
                      ? "#00ff0030"
                      : "",
                }}
              >
                <div>
                  <div>
                    <Title order={4}>
                      {entry.campaign_name}{" "}
                      {entry.status == "start" &&
                        ` (${entry.sent_rows ? entry.sent_rows : 0}/${
                          entry.total_rows
                        })`}
                    </Title>
                    <div>
                      <small>{entry.campaign_description}</small>
                    </div>
                  </div>
                  {entry.start_on && (
                    <small>
                      {intl.formatMessage({ id: "start_on" })}{" "}
                      {new Date(entry.start_on).toLocaleString()}
                    </small>
                  )}
                  <div>
                    <span title={intl.formatMessage({ id: "email_list_name" })}>
                      <MailFast
                        style={{ display: "inline-block", marginRight: 5 }}
                      />
                      {entry.email_list_name}
                    </span>
                    <span title={intl.formatMessage({ id: "template_title" })}>
                      <Template
                        style={{
                          display: "inline-block",
                          marginRight: 5,
                          marginLeft: 20,
                        }}
                      />
                      {entry.template_title}
                    </span>
                    {entry.send_from && (
                      <span title={intl.formatMessage({ id: "send_from" })}>
                        <At
                          style={{
                            display: "inline-block",
                            marginRight: 5,
                            marginLeft: 20,
                          }}
                        />
                        {entry.send_from}
                      </span>
                    )}
                  </div>
                </div>
                <div></div>
                <div>
                  {entry.status == "start" ? (
                    <PlayerPlay />
                  ) : entry.status == "stop" ? (
                    <PlayerPause />
                  ) : entry.status == "done" ? (
                    <CircleCheck />
                  ) : (
                    <span></span>
                  )}
                </div>
              </Card>
            </a>
          ))}
      </div>
    </Card>
  );
};
