import { FormattedMessage } from "react-intl";

export const BackButton = ({ href = null }) => {
  const currentUrl = window.location.href;

  const urlObject = new URL(currentUrl);
  const pathname = urlObject.pathname;

  const regex = /\d+|new/gi;

  const cleanPathname = pathname.replace(regex, "");

  return (
    <div style={{ marginTop: 7, marginBottom: 7 }}>
      <a
        style={{
          backgroundColor: "dodgerblue",
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          color: "white",
          borderRadius: 5,
          cursor: "pointer",
        }}
        href={href ? href : cleanPathname}
      >
        <FormattedMessage id="back" />
      </a>
    </div>
  );
};
