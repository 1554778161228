import React, { useState, useEffect, useRef } from "react";
import { TextInput, Text, Group, Paper, Box } from "@mantine/core";
import { FormattedMessage } from "react-intl";

const AutoSelect = ({
  title,
  getData,
  callBack,
  placeholder,
  customReset,
  customStyle,
  preselected,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length > 2) {
      getData(query).then((data) => setOptions(data));
    } else {
      setOptions([]);
    }
  }, [query]);

  useEffect(() => {
    if (preselected) {
      setSelectedOption(preselected);
      setQuery("");
      setOptions([]);
    }
  }, [preselected]);

  const inputRef = useRef(null);

  const handleReset = () => {
    setSelectedOption(null);
    setQuery("");
    callBack(null);
    inputRef?.current?.focus();
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    callBack(option);
    setQuery("");
    setOptions([]);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <Group direction="column" spacing="xs">
      <Text size="sm" weight={500}>
        {title}
      </Text>
      {selectedOption ? (
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={
              customStyle
                ? customStyle
                : {
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "15px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }
            }
          >
            {selectedOption.name} {selectedOption.surname}
            {!customReset && (
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  marginLeft: "5px",
                  fontWeight: 800,
                  color: "red",
                  flex: "end",
                }}
                onClick={handleReset}
              >
                X
              </button>
            )}
          </div>
          {customReset && <span onClick={handleReset}>{customReset}</span>}
        </Box>
      ) : (
        <TextInput
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder={placeholder}
          ref={inputRef}
          style={{ width: "100%" }}
        />
      )}
      {options && options.length > 0 && (
        <Paper shadow="xs" padding="xs" style={{width: "100%", marginTop: -10}}>
          {options.map((option) => (
            <Text
              key={option.id}
              onClick={() => handleOptionClick(option)}
              style={{
                padding: 5,
                cursor: "pointer",
                "&:hover": { backgroundColor: "#f0f0f0" },
              }}
            >
              {option.name} {option.surname}
            </Text>
          ))}
        </Paper>
      )}
      {options && options.error && (
        <Text style={{ padding: 5 }}>
          <FormattedMessage id="not_found" />
        </Text>
      )}
    </Group>
  );
};

export default AutoSelect;
