import { useState, useEffect } from "react";
import { Loader, MultiSelect } from "@mantine/core";
import { getClients } from "../../functions/api";
import { useIntl } from "react-intl";

const UserMultiSelect = ({ selectedValues, setSelectedValues }) => {
  const intl = useIntl();

  const [clients, setClients] = useState([]);

  function removeNullsFromArray(array) {
    if (!Array.isArray(array)) return [];
    return array?.filter((item) => item !== null);
  }

  useEffect(() => {
    getClients().then((response) => setClients(response));
  }, []);

  return clients ? (
    <MultiSelect
      value={selectedValues && removeNullsFromArray(selectedValues)}
      limit={20}
      onChange={setSelectedValues}
      searchable
      placeholder="Search for users"
      nothingFound="No users found"
      label={intl.formatMessage({ id: "search_client" })}
      data={!clients.error ? clients : []}
    />
  ) : (
    <Loader />
  );
};

export default UserMultiSelect;
