import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
  Tooltip,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { createInvoice, getBank, getClients } from "../../functions/api";
import { AutoSelect } from "../../Components";
import { FormattedMessage, useIntl } from "react-intl";
import { useSettings } from "../../Hooks/SettingsContext";

function AddInvoiceScreen({ preclient, assignee }) {
  const intl = useIntl();
  const { defaultSettings } = useSettings(); // Assuming `true` is the condition to fetch settings

  const serviceOptions = [
    {
      value: "Consultation",
      label: intl.formatMessage({ id: "consultation" }),
    },
    { value: "Design", label: intl.formatMessage({ id: "design" }) },
    { value: "Development", label: intl.formatMessage({ id: "development" }) },
    // add more options here
  ];

  const theme = useMantineTheme();
  const [client, setClient] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [services, setServices] = useState([]);
  const [notes, setNotes] = useState("");
  const [taxRate, setTaxRate] = useState(0.21);
  const [bank, setBank] = useState(null);
  const [focused, setFocused] = useState(false);
  const [discount, setDiscount] = useState(null);

  useEffect(() => {
    let invoice_service = "";
    let cost = 0;
    if (
      defaultSettings.invoice_service &&
      defaultSettings.invoice_service !== ""
    ) {
      invoice_service = defaultSettings.invoice_service;
    }
    if (defaultSettings.invoice_price && defaultSettings.invoice_price !== "") {
      cost = defaultSettings.invoice_price;
    }
    setServices([{ name: invoice_service, cost: cost }]);
  }, [defaultSettings]);

  useEffect(() => {
    if (preclient) {
      setClient(preclient);
    }
  }, []);

  const handleAddService = () => {
    setServices([...services, { name: "", cost: 0 }]);
  };

  const submitCreateInvoice = async () => {
    if (!client) {
      return window.alert(intl.formatMessage({ id: "select_a_client" }));
    }
    if (!services) {
      return window.alert(intl.formatMessage({ id: "add_a_service" }));
    }
    if (!bank) {
      return window.alert(intl.formatMessage({ id: "select_credentials" }));
    }

    createInvoice({
      client,
      dueDate,
      services,
      notes,
      taxRate,
      bank,
      discount,
    }).then((data) => window.location.reload(false));
  };

  const handleRemoveService = (index) => {
    const newServices = [...services];
    newServices.splice(index, 1);
    setServices(newServices);
  };

  const handleServiceChange = (value, index, key) => {
    const newServices = [...services];
    if (value && value === "other") {
      newServices[index][key] = "";
      newServices[index]["customServiceName"] = true;
    } else if (value) {
      newServices[index][key] = value;
      newServices[index]["customServiceName"] = false;
    } else {
      newServices[index][key] = "";
      newServices[index]["customServiceName"] = false;
    }
    setServices(newServices);
  };

  const handleCustomServiceNameChange = (event, index) => {
    const newServices = [...services];
    newServices[index]["name"] = event.target.value;
    setServices(newServices);
  };

  const handleDueDateChange = (value) => {
    setDueDate(value);
  };

  const subtotal = services.reduce(
    (total, service) => Number(total) + Number(service.cost),
    0
  );
  const amountDue = subtotal * (1 + Number(taxRate));

  return (
    <div style={{ padding: theme.spacing.md }}>
      <Text style={{ fontSize: theme.fontSizes.xl }}>
        {intl.formatMessage({ id: "add_new_invoice" })}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.sm,
          marginTop: theme.spacing.md,
        }}
      >
        <AutoSelect
          title={intl.formatMessage({ id: "select_client" })}
          placeholder={intl.formatMessage({ id: "search_client" })}
          preselected={preclient ? preclient : null}
          getData={(data) => getClients(data, true)}
          callBack={setClient}
        />
        <AutoSelect
          title={intl.formatMessage({ id: "select_issuer_credentials" })}
          placeholderx={intl.formatMessage({
            id: "search_credentials_company_name",
          })}
          placeholder={intl.formatMessage({ id: "write_ltd_select" })}
          getData={(data) => getBank(data)}
          callBack={setBank}
        />
        <DateInput
          label={intl.formatMessage({ id: "due_date" })}
          value={dueDate}
          onChange={handleDueDateChange}
          style={{ marginBottom: 10 }}
        />
      </div>
      {services.map((service, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <div style={{ flexBasis: "45%" }}>
            <Input
              placeholder={intl.formatMessage({ id: "enter_service_name" })}
              value={service.name}
              onChange={(event) => handleCustomServiceNameChange(event, index)}
              style={{ marginBottom: 5 }}
            />
          </div>
          <div style={{ flexBasis: "45%" }}>
            <Input
              placeholder={intl.formatMessage({ id: "cost" })}
              value={service.cost}
              type="number"
              onChange={(event) =>
                handleServiceChange(event.target.value, index, "cost")
              }
              style={{ marginBottom: 5 }}
            />
          </div>
          <Button
            variant="outline"
            color="red"
            onClick={() => handleRemoveService(index)}
            style={{ flexBasis: "10%", marginBottom: 5 }}
          >
            <FormattedMessage id="remove" />
          </Button>
        </div>
      ))}
      <Button
        style={{ marginBottom: 5 }}
        onClick={handleAddService}
        variant="outline"
        color="gray"
        fullWidth
      >
        <FormattedMessage id="add_service" />
      </Button>
      <div style={{ marginTop: theme.spacing.md }}>
        <TextInput
          label={intl.formatMessage({ id: "tax_rate" })}
          placeholder={intl.formatMessage({ id: "tax_rate" })}
          value={taxRate}
          type="number"
          onChange={(event) => setTaxRate(event.target.value)}
          style={{ marginBottom: 5 }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          inputContainer={(children) => (
            <Tooltip
              label={intl.formatMessage({ id: "vat_hundredths" })}
              position="top-start"
              opened={focused}
            >
              {children}
            </Tooltip>
          )}
        />
        <TextInput
          placeholder="Discount %"
          value={discount}
          type="number"
          label="Discount %"
          onChange={(event) => setDiscount(event.target.value)}
          style={{ marginBottom: 5 }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Text
            style={{
              fontWeight: 600,
              marginRight: 5,
              flexBasis: "22%",
            }}
          >
            {intl.formatMessage({ id: "subtotal" })}
          </Text>
          <Text>${Number(subtotal).toFixed(2)}</Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
            color: "gray",
          }}
        >
          {taxRate && taxRate > 0 && (
            <>
              <Text
                style={{
                  fontWeight: 600,
                  marginRight: 5,
                  fontSize: 13,
                  flexBasis: "22%",
                }}
              >
                {taxRate * 100}% {intl.formatMessage({ id: "tax" })}
              </Text>
              <Text style={{ color: "gray", fontSize: 13 }}>
                ${Number(amountDue - subtotal).toFixed(2)}
              </Text>
            </>
          )}
        </div>
        {discount && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 5,
                flexBasis: "22%",
              }}
            >
              Total:
            </Text>
            <Text>${Number(amountDue).toFixed(2)}</Text>
          </div>
        )}
        {discount && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Text
              style={{
                fontWeight: 600,
                marginRight: 5,
                flexBasis: "22%",
              }}
            >
              Discount:
            </Text>
            <Text>
              ${(Number(amountDue).toFixed(2) * (discount / 100)).toFixed(2)}
            </Text>
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Text
            style={{
              fontWeight: 600,
              marginRight: 5,
              flexBasis: "22%",
            }}
          >
            {intl.formatMessage({ id: "total_due" })}
          </Text>
          <Text>
            $
            {discount
              ? (Number(amountDue).toFixed(2) * (1 - discount / 100)).toFixed(2)
              : Number(amountDue).toFixed(2)}
          </Text>
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Text
            style={{
              fontWeight: 600,
              marginRight: 5,
              flexBasis: "22%",
            }}
          >
            Total without discou
          </Text>
          <Text>${Number(amountDue).toFixed(2)}</Text>
        </div> */}
        <Textarea
          placeholder={intl.formatMessage({ id: "notes" })}
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          style={{ marginBottom: 5 }}
        />
        <Button
          variant="filled"
          onClick={() => submitCreateInvoice()}
          style={{ backgroundColor: "blue" }}
          fullWidth
        >
          <FormattedMessage id="create_invoice" />
        </Button>
      </div>
    </div>
  );
}

export default AddInvoiceScreen;
