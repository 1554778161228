import React from "react";
import { Link } from "react-router-dom";
import { ExternalLink } from "tabler-icons-react";
import { Text, Badge } from "@mantine/core";
//import "primereact/resources/themes/saga-blue/theme.css";
//import "primereact/resources/primereact.min.css";
import { Sidebar } from "primereact/sidebar";
import PinButton from "../Buttons/PinButton";
import { FormattedMessage, useIntl } from "react-intl";

export default function SideOverlay({ open, setOpen, data }) {
  const intl = useIntl();
  const customIcons = (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {data && data.route && (
          <div style={{ marginRight: 20, marginBottom: 5 }}>
            <Link
              to={{ pathname: data.route, query: { foo: "bar" } }}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "dodgerblue" }}
            >
              <ExternalLink
                size={16}
                style={{
                  display: "inline-block",
                  paddingBottom: 1,
                }}
              />
              <Text
                style={{
                  display: "inline-block",
                  fontSize: 14,
                  textDecorationLine: "underline",
                }}
              >
                {" "}
                {intl.formatMessage({ id: "open_new_tab" })}
              </Text>
            </Link>
          </div>
        )}

        {data && (data.clientid || data.taskid) && (
          <div style={{ marginRight: 20 }}>
            <PinButton
              itemType={data.taskid ? "task" : "client"}
              itemId={data.taskid ? data.taskid : data.clientid}
            />
          </div>
        )}
      </div>
      {/* <button className="p-sidebar-icon p-link mr-2">
        <span className="pi pi-search" />
      </button> */}
    </React.Fragment>
  );

  return (
      <Sidebar
        pt={{
          // mask: { className: "zero-index" },
          root: {}
        }}
        style={{ width: 450 }}
        icons={customIcons}
        visible={open}
        position="right"
        onHide={() => setOpen(false)}
      >
        {data && (
          <h2 style={{ fontWeight: "bold", fontSize: 18 }}>{data.title}</h2>
        )}
        {data && data.time && data.reason && (
          <div style={{ width: "100%" }}>
            <Badge color="red" variant="filled" fullWidth>
              <FormattedMessage id="so_closed" />
              {new Date(data.time).toLocaleDateString()} - {data.reason}
            </Badge>
          </div>
        )}
        {data && data.component ? (
          data.component
        ) : (
          <div className="absolute inset-0 px-4 sm:px-6">
            <div
              className="h-full border-2 border-dashed border-gray-200"
              aria-hidden="true"
            />
          </div>
        )}
      </Sidebar>
  );
}
