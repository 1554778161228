import { Navigate, Route, Routes } from "react-router-dom";
import {
  ForgotPasswordScreen,
  PassResetScreen,
} from "../Screens/User/LoginScreen";
import { MarketingScreenRoutes } from "../Screens/Admin/Marketing/MarketingScreen";
import hasAccess from "../functions/access";
import { getGroup, getLanguage, getToken } from "../functions/tokens";
import React, { useState } from "react";
import LoadingFallback from "../Components/Overlays/LoadingFallback";
import AdminReports from "../Screens/Admin/AdminReports";
import { Logout } from "../Screens/User/LogoutScreen";

const LoginScreen = React.lazy(() => import("../Screens/User/LoginScreen"));
const LeadScreen = React.lazy(() => import("../Screens/User/LeadScreen"));
const HomeScreen = React.lazy(() => import("../Screens/User/HomeScreen"));
const PartnerScreen = React.lazy(() =>
  import("../Screens/Client/PartnerScreen")
);
const EventScreen = React.lazy(() => import("../Screens/Client/EventScreen"));
const ProfileScreen = React.lazy(() =>
  import("../Screens/Client/ProfileScreen")
);
const FileScreen = React.lazy(() => import("../Screens/Client/FileScreen"));
const CalendarScreen = React.lazy(() =>
  import("../Screens/User/CalendarScreen")
);
const MarketingScreen = React.lazy(() =>
  import("../Screens/Admin/Marketing/MarketingScreen")
);
const ClientOverviewScreen = React.lazy(() =>
  import("../Screens/Client/ClientOverviewScreen")
);
const TaskOverviewScreen = React.lazy(() =>
  import("../Screens/User/TaskScreen")
);
const InvoicesPage = React.lazy(() => import("../Screens/Client/InvoicesPage"));
const AddInvoiceScreen = React.lazy(() =>
  import("../Screens/Client/AddInvoiceScreen")
);
const InvoicePage = React.lazy(() => import("../Screens/Client/InvoicePage"));
const PDFscreen = React.lazy(() => import("../Screens/Client/PDFscreen"));
const SettingScreen = React.lazy(() =>
  import("../Screens/Admin/Settings/SettingScreen")
);
const AdminTasks = React.lazy(() => import("../Screens/Admin/AdminTasks"));
// const AdminClientScreen = React.lazy(() =>
//   import("../Screens/Admin/AdminClients")
// );

const AppRoutes = ({ setMargins, isLoggedIn, setLoggedin, shwBr }) => {
  const [locale, setLocale] = useState(
    getLanguage() || navigator.language || "en-GB"
  );

  const RedirectWrapper = () => (
    <Navigate to={isLoggedIn ? "/main" : "/login"} />
  );

  const LanguageSwitch = () => {
    setLocale(locale == "en-GB" ? "lv-LV" : "en-GB");
    RedirectWrapper();
    return null;
  };

  let group = getGroup();
  group = group == null ? 10 : group;

  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route
          exct
          path="/reset-password/:token"
          element={<PassResetScreen />}
        />
        <Route
          exct
          path="/forgot-password"
          element={<ForgotPasswordScreen />}
        />
        {!getToken() && (
          <Route exct path="/main" element={<RedirectWrapper />} />
        )}
        {isLoggedIn && hasAccess(group, "leads") ? (
          <Route exct path="/main" element={<LeadScreen />} />
        ) : (
          <>
            <Route exct path="/main" element={<HomeScreen />} />
            {isLoggedIn && hasAccess(group, "partner") && (
              <>
                <Route exct path="/partner/:id" element={<PartnerScreen />} />
                <Route
                  exct
                  path="/partner/new"
                  element={<PartnerScreen newEntry={true} />}
                />
                <Route exct path="/event/:id" element={<EventScreen />} />
              </>
            )}
            {isLoggedIn && hasAccess(group, "invoices") && (
              <>
                <Route exct path="/invoices" element={<InvoicesPage />} />
                {isLoggedIn && hasAccess(group, "invoices", "insert") && (
                  <Route
                    exct
                    path="/invoices/new"
                    element={<AddInvoiceScreen />}
                  />
                )}
                <Route exct path="/invoices/:id" element={<InvoicePage />} />
              </>
            )}
            {isLoggedIn && hasAccess(group, "Admin") && (
              <>
                <Route
                  exct
                  path="/admin-reports/:tab"
                  element={<AdminReports />}
                />
                {MarketingScreenRoutes()}
                <Route
                  exct
                  path="/admin/system-settings"
                  element={
                    <SettingScreen
                      showBarCtrl={shwBr}
                      marginCtrl={setMargins}
                    />
                  }
                />
              </>
            )}
            {isLoggedIn && hasAccess(group, "adminTasks") && (
              <Route exct path="/admin-tasks" element={<AdminTasks />} />
            )}

            <Route exct path="/profile" element={<ProfileScreen />} />
            <Route exct path="/files" element={<FileScreen />} />
            <Route exct path="/calendar" element={<CalendarScreen />} />
            <Route exct path="/calendar/:day" element={<CalendarScreen />} />
            <Route exct path="/marketing" element={<MarketingScreen />} />
            <Route
              exct
              path="/clients"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ClientOverviewScreen />
                </React.Suspense>
              }
            />
            <Route exct path="/tasks" element={<TaskOverviewScreen />} />
            <Route exct path="/pdfs/:id" element={<PDFscreen />} />
          </>
        )}
        {isLoggedIn && (
          <>
            <Route exct path="/profile" element={<ProfileScreen />} />
          </>
        )}
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/main" /> : <LoginScreen />}
        />
        <Route
          exct
          path="/logout"
          element={<Logout setLoggedin={setLoggedin} />}
        />
        <Route path="/en" element={<LanguageSwitch />} />
        <Route path="/lv" element={<LanguageSwitch />} />
        <Route path="*" element={<RedirectWrapper />} />
      </Routes>
    </React.Suspense>
  );
};

export default AppRoutes;
