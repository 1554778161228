import { FormattedMessage } from "react-intl";

export const NewButton = ({ href }) => {
  return (
    <a
      style={{
        backgroundColor: "dodgerblue",
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        borderRadius: 5,
        cursor: "pointer",
      }}
      href={href}
    >
      <FormattedMessage id="new" />
    </a>
  );
};
