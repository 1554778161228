import React from "react";
import { useIntl } from "react-intl";
import { Location } from "tabler-icons-react";

const OpenInGoogleMaps = ({ coords, address }) => {
  const intl = useIntl();
  const openInMaps = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${coords.y},${coords.x}`;
    window.open(url, "_blank");
  };

  return (
    <a
    title={intl.formatMessage({id: "open_new_tab"})}
      onClick={openInMaps}
      style={{ display: "flex", alignItems: "center", overflow: "hidden", cursor: "pointer" }}
    >
      <Location size={8} />
      <span style={{ fontSize: 11, marginLeft: 5, fontWeight: "200" }}>
        {address}
      </span>
    </a>
  );
};

export default OpenInGoogleMaps;
